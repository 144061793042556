// Variables
// ---------------------------------------/

$container-width: 980px !default;
$grid-gutter: 10px !default;

// Colors
$black: #000;
$almostblack: #252525;
$darkgray: #333;
$darkishgray: #505050;
$darkergray: #939393;
$gray: #a5a5a5;
$lightgray: #c7c7c7;
$almostwhite: #dddddd;
$white: #fff;
$red: #FF0000FF;
$yellow: #ff0;

// Styles
$text_color: $almostblack;

// Font stack
$body-font: Helvetica, arial, nimbussansl, liberationsans, freesans, clean, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol" !default;

// The base body size
$body-font-size: 13px !default;

// Monospace font stack
$mono-font: Consolas, "Liberation Mono", Menlo, Courier, monospace !default;