.print-only {
  display: none !important
}

@media print {
  .no-print {
    display: none !important
  }
  .print-only {
    display: block !important
  }
}